import { useQuery } from '@apollo/client';

import { useAppFilters } from 'web/contexts/AppFiltersContext';

import Filter from 'components/filters/Filter';

import { PAGINATE_TENANT_ADMINS_AND_ACCOUNTANTS } from 'api/queries/userQueries';

const allOption = { value: '', label: 'Alle regnskapsførere' };

export default function AdminsAndAccountantsFilter({
	disabled = false,
	size = 'medium',
}) {
	const { accountant, setAccountant } = useAppFilters();

	const {
		data: { adminsAndAccountants = { count: 0, items: [] } } = {
			adminsAndAccountants: { count: 0, items: [] },
		},
		loading,
	} = useQuery(PAGINATE_TENANT_ADMINS_AND_ACCOUNTANTS, {
		variables: {
			order: 1,
			orderBy: 'name',
			limit: 100,
		},
	});

	return (
		<Filter
			name="admins-and-accountants-filter"
			label="Vis regnskapsfører"
			value={accountant || allOption}
			options={[
				allOption,
				...adminsAndAccountants.items.map(accountant => ({
					value: accountant._id,
					label: accountant.name,
				})),
			]}
			onChange={setAccountant}
			loading={loading}
			disabled={disabled}
			size={size}
		/>
	);
}
