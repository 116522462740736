import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import IconButton from '@mui/material/Icon';
import Button from '@mui/material/Button';

import DesktopLayout, { NarrowContent } from 'web/components/Layout';
import Loading from 'components/Loading';
import Icon from 'components/Icon';

import { GET_ONE_COMPANY_APPLICATION } from 'api/queries/companyApplicationQueries';
import { DELETE_ONE_COMPANY_APPLICATION } from 'api/mutations/companyApplicationMutations';

const Shareholders = styled.div`
	& > div {
		padding: 10px 10px 1px;

		&:nth-of-type(odd) {
			background: #f1f1f1;
		}
	}
`;

const Section = styled.div`
	padding: 10px 10px 1px;
	background: #f1f1f1;
`;

const Header = styled.h3`
	margin: 30px 0;
	border-bottom: 2px solid #f1f1f1;
`;
const StyledButton = styled(Button)`
	&& {
		margin: 14px 0 16px;
		padding: 13px 30px;
	}
`;

export default function CompanyApplicationSingle() {
	const [active, setActive] = useState('');
	const { companyApplicationId } = useParams();
	const navigate = useNavigate();

	const {
		data: { companyApplication } = {},
		loading,
		error,
	} = useQuery(GET_ONE_COMPANY_APPLICATION, {
		variables: {
			_id: companyApplicationId,
		},
	});

	const [deleteOneCompanyApplication, { data }] = useMutation(
		DELETE_ONE_COMPANY_APPLICATION
	);

	useEffect(() => {
		if (!data) {
			return;
		}

		navigate('/soknader/');
	}, [data]);

	if (error) {
		navigate('/404/');
	}

	if (loading || !companyApplication?._id) return <Loading />;

	return (
		<DesktopLayout
			breadcrumbs={[
				{
					to: '/soknader/',
					label: 'Søknader',
				},
				{
					label: companyApplication?.name,
				},
			]}
		>
			<NarrowContent>
				<Header>Søker</Header>

				<Section>
					<EntityDisplay
						show
						entity={companyApplication.contact}
						active={active}
						isPerson={true}
						setActive={setActive}
						groupId="contact"
					/>
				</Section>

				<Header>Selskapets navn</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						label="Navn"
						value={companyApplication?.companyName}
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						label="Stiftelsesdato"
						value={companyApplication?.companyFoundationDate}
					/>
				</Section>

				<Header>Forretningsadresse</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.companyAddress}
						label="Adresse"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.companyPostalCode}
						label="Postnr"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.companyCounty}
						label="Sted"
					/>
				</Section>

				<Header>Vedtektsfestet formål</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.companyPurpose}
						label="Formål"
					/>
				</Section>

				<Header>Aksjekapital i selskapet</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.stockCapital}
						label="Aksjekapitalen"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.stockAmount}
						label="Antall aksjer"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.stockPremium}
						label="Overkurs"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.stockItemDeposit}
						label="Tingsinnskudd"
					/>
				</Section>

				<Header>Aksjonærer</Header>

				<Shareholders>
					{companyApplication?.stockholders?.map(
						(stockholder, index) => (
							<EntityDisplay
								header={`Aksjonær ${index + 1}`}
								show
								groupId={stockholder._id}
								key={stockholder._id}
								entity={stockholder}
								active={active}
								setActive={setActive}
								displayShares
								displayIdentifier
							/>
						)
					)}
				</Shareholders>

				{/* Den nye versjonen */}

				<Header>Daglig leder, kontaktperson</Header>

				<Section>
					<EntityDisplay
						show
						entity={companyApplication.primaryContact}
						active={active}
						setActive={setActive}
						groupId="primaryContact"
						isPerson={true}
						header={companyApplication?.primaryContact?.role}
					/>
				</Section>

				<Header>Valg av styre og revisor</Header>

				<Shareholders>
					{companyApplication?.boardMembers?.map(member => (
						<EntityDisplay
							header={member.role}
							show
							groupId={member._id}
							key={member._id}
							entity={member}
							active={active}
							setActive={setActive}
							isPerson={true}
							displayShares
							displayIdentifier
						/>
					))}
				</Shareholders>

				<Header>Regnskapskontor</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.accountingOffice}
						label="Regnskpskontor"
					/>
				</Section>

				<Header>Revisor</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.auditor}
						label="Revisor"
					/>
				</Section>

				<Header>Signatur</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.signatories}
						label="Selskapets firma tegnes av"
					/>
				</Section>

				<Header>Prokura</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.procura}
						label="Procura"
					/>
				</Section>

				<Header>Vedtekter</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.bylaws}
						label="Vedtekter"
					/>
				</Section>

				<Header>Stiftelsesutgifter</Header>

				<Section>
					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.registrationFee}
						label="Brønnøysundregistrerne"
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={companyApplication?.auditorFee}
						label="Admento AS"
					/>
				</Section>

				<StyledButton
					variant="contained"
					color="primary"
					type="button"
					onClick={async () => {
						await deleteOneCompanyApplication({
							variables: {
								_id: companyApplicationId,
							},
							refetchQueries: ['paginateCompanyApplications'],
						});
					}}
				>
					<Icon icon="trash" style={{ marginRight: '10px' }} />
					Arkiver søknad
				</StyledButton>
			</NarrowContent>
		</DesktopLayout>
	);
}

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;
const Label = styled.div`
	font-weight: 700;
	font-style: italic;
	padding-right: 15px;
`;
const Value = styled.div`
	margin: 0 10px 0 auto;
`;
const StyledIcon = styled(Icon)`
	color: ${p => (p.active ? p.theme.palette.primary.main : 'black')};
	font-size: 16px;
`;

function InfoRow({ label, value, active, setActive, groupId }) {
	return (
		<Row>
			<Label>{label}</Label>

			<Value>{value}</Value>

			<IconButton
				aria-label="Kopier verdi"
				onClick={() => {
					setActive(groupId ? groupId + label : label);
					navigator.clipboard.writeText(value);
				}}
			>
				<StyledIcon
					active={
						groupId ? active === groupId + label : active === label
					}
					icon="copy"
				/>
			</IconButton>
		</Row>
	);
}

const EntityHeader = styled.div`
	font-weight: 700;
	/* font-style: italic; */
	margin-bottom: 10px;
`;
function EntityDisplay({
	show,
	entity,
	displayShares,
	displayIdentifier,
	displayRole,
	active,
	setActive,
	groupId,
	header,
}) {
	const [visible, setVisible] = useState(show || false);

	return (
		<div>
			{header && (
				<Row>
					<EntityHeader>{header}</EntityHeader>

					<IconButton
						aria-label="minimer"
						onClick={() => setVisible(!visible)}
					>
						<StyledIcon
							solid
							icon={visible ? 'caret-up' : 'caret-down'}
						/>
					</IconButton>
				</Row>
			)}
			<InfoRow
				active={active}
				setActive={setActive}
				value={entity?.name}
				label="Navn"
				groupId={groupId}
			/>
			{visible && (
				<>
					{displayIdentifier && (
						<InfoRow
							active={active}
							setActive={setActive}
							value={entity?.identifier}
							label="Orgnr/Personnr"
							groupId={groupId}
						/>
					)}

					{displayRole && (
						<InfoRow
							active={active}
							setActive={setActive}
							value={entity?.address}
							label="Adresse"
							groupId={groupId}
						/>
					)}

					<InfoRow
						active={active}
						setActive={setActive}
						value={entity?.address}
						label="Adresse"
						groupId={groupId}
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={entity?.postalCode}
						label="Postnummer"
						groupId={groupId}
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={entity?.county}
						label="Sted"
						groupId={groupId}
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={entity?.phone}
						label="Telefon"
						groupId={groupId}
					/>

					<InfoRow
						active={active}
						setActive={setActive}
						value={entity?.email}
						label="Epost"
						groupId={groupId}
					/>

					{displayShares && (
						<InfoRow
							active={active}
							setActive={setActive}
							value={entity?.shares}
							label="Aksjeandel"
							groupId={groupId}
						/>
					)}
				</>
			)}
		</div>
	);
}
