import styled from 'styled-components';

import SearchFilter from 'components/filters/SearchFilter';
import AppFilters from 'components/filters/AppFilters';
import AppActiveFilters from 'components/filters/AppActiveFilters';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	margin-bottom: 20px;
`;

const Column = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`;

export default function AppFiltersWithActiveState({
	searchLabel = null,
	displayFilters = [],
	size = 'medium',
}) {
	if (!displayFilters || displayFilters.length === 0) {
		throw new Error('At least one displayFilter is required');
	}

	return (
		<Wrapper>
			<Column>
				{searchLabel && (
					<SearchFilter label={searchLabel} size={size} />
				)}

				<AppFilters displayFilters={displayFilters} size={size} />
			</Column>

			<Column>
				<AppActiveFilters displayFilters={displayFilters} />
			</Column>
		</Wrapper>
	);
}
