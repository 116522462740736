import styled from 'styled-components';

import { useAppFilters } from 'web/contexts/AppFiltersContext';
import { useUser } from 'web/contexts/UserContext';

import TaskFilter from 'components/filters/TaskFilter';
import MonthFilter from 'components/filters/MonthFilter';
import YearFilter from 'components/filters/YearFilter';
import DepartmentFilter from 'components/filters/DepartmentFilter';
import AdminsAndAccountantsFilter from 'components/filters/AdminsAndAccountantsFilter';
import AccountantRoleFilter from 'components/filters/AccountantRoleFilter';
import AccountantRoleFilterLegacy from 'components/filters/AccountantRoleFilterLegacy';
import CompanyFilter from 'components/filters/CompanyFilter';
import UserRolesFilter from 'components/filters/UserRolesFilter';
import AccountTypeFilter from 'components/filters/AccountTypeFilter';
import AuditingCompanyFilter from 'components/filters/AuditingCompanyFilter';

const Filters = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;
`;

export default function AppFilters({ displayFilters, size = 'medium' }) {
	const { userHasMinRole } = useUser();
	const { accountant } = useAppFilters();

	const allFilters = [
		{
			id: 'year',
			display: true,
			disabled: false,
			component: YearFilter,
		},
		{
			id: 'month',
			display: true,
			disabled: false,
			component: MonthFilter,
		},
		{
			id: 'task',
			display: true,
			disabled: false,
			component: TaskFilter,
		},
		{
			id: 'department',
			display: userHasMinRole('admin'),
			disabled: false,
			component: DepartmentFilter,
		},
		{
			id: 'adminsAndAccountants',
			display: userHasMinRole('admin'),
			disabled: false,
			component: AdminsAndAccountantsFilter,
		},
		{
			id: 'accountantRole',
			display: true,
			disabled:
				userHasMinRole('admin') && (!accountant || !accountant.value),
			component: AccountantRoleFilter,
		},
		{
			id: 'accountantRoleLegacy',
			display: true,
			disabled: false,
			component: AccountantRoleFilterLegacy,
		},
		{
			id: 'userRole',
			display: true,
			disabled: false,
			component: UserRolesFilter,
		},
		{
			id: 'company',
			display: true,
			disabled: false,
			component: CompanyFilter,
		},
		{
			id: 'accountType',
			display: true,
			disabled: false,
			component: AccountTypeFilter,
		},
		{
			id: 'auditingCompany',
			display: true,
			disabled: false,
			component: AuditingCompanyFilter,
		},
	];

	const activeFilters = allFilters.filter(
		filter => filter.display && displayFilters.includes(filter.id)
	);

	return (
		<Filters>
			{activeFilters.map(filter => {
				const FilterComponent = filter.component;

				return (
					<FilterComponent
						key={filter.id}
						size={size}
						disabled={filter.disabled}
					/>
				);
			})}
		</Filters>
	);
}
